import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const nonVarFrameKeys = [
    'barLevels',
]

interface AppState {
    enabled: boolean;
    particlesEnabled: boolean;
    spotify_token: any,
    latex_strings: string[];
    code_strings: any[];
    function_codes: any[];
    internal_floats: Record<string, number>;
    slider_names: Record<string, string[]>;
    showBars: boolean;
    isDarkTheme: boolean;
    track: any;
    variants: Record<string, Record<string, { latex_strings: string[]; internal_floats: Record<string, number> }>>;
}
/*
const loadVariantsFromStorage = (): Record<string, Record<string, { latex_strings: string[]; internal_floats: Record<string, number> }>> => {
    const variants = localStorage.getItem('variants');
    return variants ? JSON.parse(variants) : {};
};

const persistVariantsToStorage = (variants: Record<string, Record<string, { latex_strings: string[]; internal_floats: Record<string, number> }>>) => {
    localStorage.setItem('variants', JSON.stringify(variants));
};

export const loadSpotifyTokenFromStorage = (): any => {
    const token_info = localStorage.getItem('spotify_token');
    return token_info ? JSON.parse(token_info) : {};
};

const persistSpotifyTokenToStorage = (token_info: any) => {
    localStorage.setItem('spotify_token', JSON.stringify(token_info));
}*/

export const initialState: AppState = {
    enabled: true,
    particlesEnabled: true,
    spotify_token: {},
    latex_strings: [
        "h=x-\\sin \\left(y\\right)",
        "s=0",
        "v=0",
        "a=0",
        "p_r=0",
        "p_g=0",
        "p_b=0",
        "r_m=0",
        "g_m=0",
        "b_m=0",
        "h_i=0",
        "h_a=1",
        "h_o=0",
        "h_s=1",
        "h_l=0",
        "s_i=0",
        "s_a=1",
        "s_o=0",
        "s_s=1",
        "s_l=0",
        "v_i=0",
        "v_a=1",
        "v_o=0",
        "v_s=1",
        "v_l=0",
        "a_i=0",
        "a_a=1",
        "a_o=0",
        "a_s=1",
        "a_l=0",
        "h_m=t_m\\left(h, h_s, h_a, h_i\\right)",
        "s_m=e_m\\left(s, s_l, s_s\\right)",
        "v_m=e_m\\left(v, v_l, v_s\\right)",
        "a_m=e_m\\left(a, a_l, a_s\\right)",
        "e_m\\left(x, l, k\\right)=(1-2l)e^{-kx^{2}}+l",
        "t_m\\left(x, s, m, n\\right)=\\arctan\\left(sx\\right)\\frac{\\left(m-n\\right)}{\\pi}+\\frac{\\left(m+n\\right)}{2}",
        "k=5",
    ],
    code_strings: [],
    function_codes: [],
    showBars: false,
    isDarkTheme: false,
    internal_floats: {},
    slider_names: {},
    track: {},
    variants: {}//loadVariantsFromStorage(),
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setEnabled(state, action: PayloadAction<boolean>) {
            state.enabled = action.payload;
        },
        setParticlesEnabled(state, action: PayloadAction<boolean>) {
            state.particlesEnabled = action.payload;
        },
        setCodeStrings(state, action: PayloadAction<any>) {
            state.code_strings = action.payload;
        },
        setFunctionStrings(state, action: PayloadAction<any>) {
            state.function_codes = action.payload;
        },
        setLatexStringAtIndex: (state, action: PayloadAction<{ index: number, value: string }>) => {
            if (action.payload.index < state.latex_strings.length) {
                state.latex_strings[action.payload.index] = action.payload.value;
            } else {
                state.latex_strings.push(action.payload.value);
            }
        },
        setSliderFloats(state, action: PayloadAction<any>) {
            state.slider_names = action.payload;
        },
        removeInternalFloatsNotInList(state, action: PayloadAction<string[]>) {
            for (const floatKey in state.internal_floats) {
                if (!action.payload.includes(floatKey)) {
                    console.log('deleting ', floatKey)
                    // delete state.internal_floats[floatKey];
                }
            }
        },
        setInternalFloat(state, action: PayloadAction<{ name: string, value: number }>) {
            state.internal_floats[action.payload.name] = action.payload.value;
        },
        addNewInternalFloat(state, action: PayloadAction<string>) {
            if (!(action.payload in state.internal_floats)) {
                state.internal_floats[action.payload] = 1.0;
            }
        },
        setShowBars(state, action: PayloadAction<boolean>) {
            state.showBars = action.payload;
        },
        setTrack(state, action: PayloadAction<any>) {
            if (state.track !== action.payload) {
                state.track = action.payload;
            }
        },
        toggleDarkTheme(state) {
            state.isDarkTheme = !state.isDarkTheme;
        },
        setVariant(state, action: PayloadAction<{ systemName: string; variantName: string }>) {
            const { systemName, variantName } = action.payload;

            if (!state.variants[systemName]) {
                state.variants[systemName] = {};
            }

            state.variants[systemName][variantName] = {
                latex_strings: [...state.latex_strings],
                internal_floats: { ...state.internal_floats },
            };

            // Persist to localStorage
            //persistVariantsToStorage(state.variants);
        },
        loadVariant(state, action: PayloadAction<{ systemName: string, variantName: string }>) {
            const variant = state.variants[action.payload.systemName]?.[action.payload.variantName];

            if (variant) {
                state.latex_strings = [...variant.latex_strings];
                state.internal_floats = { ...variant.internal_floats };
            }
        },
        setSpotifyToken(state, action: PayloadAction<any>) {
            state.spotify_token = action.payload;
           // persistSpotifyTokenToStorage(action.payload);
        },
    },
});

export const {
    setEnabled, setParticlesEnabled, setCodeStrings, setShowBars, setTrack, setFunctionStrings, setLatexStringAtIndex, setInternalFloat,
    setSliderFloats, addNewInternalFloat, toggleDarkTheme,
    setVariant, loadVariant, removeInternalFloatsNotInList, setSpotifyToken
} = appSlice.actions;

export default appSlice.reducer;
